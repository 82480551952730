@use "../../common/colors.module";

h1 {
  text-align: center;
}

.homePageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
}

.homePageContent {
  width: 30%;
  height: 100%;
  background: colors.$white;
  box-shadow: 5px 5px 5px colors.$darkGrey;
  padding: 0 2em 1em 2em;
  word-break: break-word;
}
