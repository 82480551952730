@use "../../common/colors.module";

h1 {
  text-align: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.content {
  width: 30%;
  background: colors.$white;
  box-shadow: 0.5em 0.5em 0.5em colors.$darkGrey;
  word-break: break-word;
  padding: 0% 2% 0% 2%;
  margin-bottom: 2%;
	border-radius: 0.5em;
}

.toggleSwitchArea {
	display: flex;
	justify-content: space-around;
	margin-bottom: 0.5%;

	> p {
		margin: 0;
		align-self: center;
	}
}

.toggleSwitch {
	input[type=checkbox]{
		height: 0;
		width: 0;
		display: none;

		&:checked + label {
			background: colors.$primaryColor; 
		}

		&:checked + label:after {
			left: calc(100% - 0.3em);
			transform: translateX(-100%);
		}
	}

	label {
		cursor: pointer;
		width: 5em;
		height: 2em;
		background: colors.$lighterGrey;
		display: block;
		border-radius: 100px;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 5px;
			left: 5px;
			width: 1.4em;
			height: 1.4em;
			background: colors.$white;
			border-radius: 90px;
			transition: 0.3s;
		}

		&:active:after {
			width: 2em;
		}
	}
}
