// Shared Colors
$primaryColor: #1DACD6;
$secondaryColor: #D6481D;
$white: #FFFFFF;
$darkGrey: #A6A6A6;
$lighterGrey: #CCCCCC;

// App
$main-background: #FFFFE0;
$card-text-color: #1D3742;

// Tabs
$hoverBorder: rgba(0,0,0, 0.25);
$active-tab-border: rgba(214, 72, 29, 0.75);

// Modal Defaults
$modalBackground: rgba(0, 0, 0, 0.5);
$modalBodyBorder: #EEEEEE
