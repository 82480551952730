@use "../../common/colors.module";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: colors.$modalBackground;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent {
  width: 40em;
  background-color: colors.$white;
}

.modalHeader, .modalFooter {
  padding: 1em;
}

.modalTitle {
  margin: 0;
}

.modalBody {
  padding: 1.5em;
  border-top: 1px solid colors.$modalBodyBorder;
  border-bottom: 1px solid colors.$modalBodyBorder;
}