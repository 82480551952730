@use "../../common/colors.module";

ul {
  &.tabLabels {
    list-style-type: none;
    display: flex;
    padding-inline: 0;
    margin-block: 0;
    min-height: 2em;
    height: 100%;
    justify-content: center;
  }
}

li > button {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  height: 100%;
  cursor: pointer;
  border: 0.35em solid transparent;
  outline: 0;
  min-width: 8em;

  &.active {
    border: 0.35em solid colors.$secondaryColor;

    &:hover {
      border: 0.35em solid colors.$active-tab-border;
      opacity: 0.5;
    }
  }

  &:not(.active) {
    &:hover {
      border: 0.35em solid colors.$hoverBorder;
      opacity: 0.5;
    } 
  }
  
  > a {
    text-decoration: none;
    color: colors.$white;
    font-size: 1.5rem;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.active {
      color: colors.$secondaryColor;
    }
  }
}

.tabs {
  width: 100%;
  height: 100%;
}

.hidden {
  display: none;
}
