@use "../../common/colors.module";

.card {
  width: 34%;
  background: colors.$white;
  box-shadow: 0.5em 0.5em 0.5em colors.$darkGrey;
  margin-bottom: 2%;
  box-sizing: border-box;
  border-radius: 0.5em;
  overflow: hidden;
}

.content {
  word-break: break-word;
  padding: 2% 2% 2% 2%;
  
  > h3 {
    margin-block-start: 0;
  }

  > ul {
    margin-block-end: 0;
  }

  > .collapsedEnd {
    margin-block-end: 0;
  }

}

.button {
  width: 100%;
  background: colors.$primaryColor;
  border: 0;
  cursor: pointer;

  > svg {
    fill: colors.$secondaryColor;
    position: relative;
    top: 0.2em;
  }
}