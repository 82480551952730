@use "./common/colors.module";

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
}

.headerBar {
  height: 100%;
  max-height: 5em;
  min-height: 2em;
  background: colors.$primaryColor;
  display: flex;
}

.bodyContainer {
  flex: 1 1 auto;
  background-color: colors.$main-background;
  min-height: 0px;
  overflow-y: auto;
  display: flex;
  width: 100%;
  justify-content: center;
}

.bodyLayout {
  width: 100%;
  max-width: 150em;
  min-width: 10em;
  margin-top: 2em;
  word-wrap: break-word;
  color: colors.$card-text-color;
  box-sizing: border-box;
}

.columnContainer {
  align-self: center;
  flex: 1 1 auto;
  min-height: 0px;
  overflow: hidden;
  padding: 0 2em 2em 2em;
}

.siteLabel {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    color: colors.$white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 2em;
    cursor: default;
  }
}

.width50 {
  height: 100%;
  max-width: 50%;
  flex-basis: 50%;
  text-align: center;
  align-self: center;
}
